import { useContext, useState } from "react";
import { FormApi } from "final-form";
import { useTranslation } from "react-i18next";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import UiButton from "../../../components/button/Button";
import UiLink from "../../../components/link/Link.component";
import UiSelect from "../../../components/select/Select.component";
import {
	DeviceSummaryFormData,
	DeviceSummaryRange,
} from "../../../models/pages/biometrics/biometrics-device-summary.model";
import { Validations } from "../../../services/form/validations.service";
import UiInputDate from "../../../components/input-date/InputDate.component";
import {
	BiometricsEmailRequest,
	BiometricsPreviewRequest,
} from "../../../models/endpoints/biometrics-endpoint.model";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../contexts/Participant.context";
import { Formatter } from "../../../services/formatter/formatter.service";
import { Form } from "react-final-form";
import FormSection from "../../../components/form-section/FormSection.component";
import UiInputRadio from "../../../components/input-radio/InputRadio.component";
import { ListOption } from "../../../models/misc.model";
import { FaTimesCircle } from "react-icons/fa";

const BiometricsReport = () => {
	const { t } = useTranslation("common");

	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);

	const [showMessage, setShowMessage] = useState({
		dateRange: false,
		reportError: false,
		reportSuccess: false,
		consentError: false,
	});

	const [submittingPreview, setSubmittingPreview] = useState<boolean>(false);

	const [formData, setFormData] = useState<DeviceSummaryFormData>({
		date: null,
		dateRange: null,
		email:
			participantContext.participant?.emails?.find((email) => email.isPrimary)
				?.emailAddress ?? null,
	});

	const mapDates = () => {
		const today = new Date();
		const result = {
			startDate: "",
			endDate: today.toISOString(),
		};

		switch (formData.date) {
			case DeviceSummaryRange.DAYS7:
				today.setDate(today.getDate() - 7);
				result.startDate = Formatter.dateUTCWithoutTime(today)!.toISOString();
				break;
			case DeviceSummaryRange.DAYS30:
				today.setDate(today.getDate() - 30);
				result.startDate = Formatter.dateUTCWithoutTime(today)!.toISOString();
				break;
			case DeviceSummaryRange.DAYS90:
				today.setDate(today.getDate() - 90);
				result.startDate = Formatter.dateUTCWithoutTime(today)!.toISOString();
				break;
			case DeviceSummaryRange.CUSTOM:
				result.startDate = Formatter.dateUTCWithoutTime(
					formData.dateRange![0]
				)!.toISOString();
				if (
					Formatter.dateUTCWithoutTime(formData.dateRange![1])!.getTime() ===
					Formatter.dateUTCWithoutTime(today)?.getTime()
				) {
					// The selected end date is today --> no need to set the last hours/minutes/Seconds of the day
					result.endDate = Formatter.dateUTCWithoutTime(
						formData.dateRange![1]
					)!.toISOString();
				} else {
					// The selected end date is NOT today --> set the last hours of the day (to search the full day)
					const endDate = formData.dateRange![1];
					endDate.setHours(23);
					endDate.setMinutes(59);
					endDate.setSeconds(59);
					result.endDate = endDate.toISOString();
				}
				break;
			default:
				break;
		}

		return result;
	};

	// Submit: generate & email report
	const handleSubmit = async (
		data: DeviceSummaryFormData,
		form: FormApi<DeviceSummaryFormData, DeviceSummaryFormData>
	) => {
		// Clean previous error messages
		setShowMessage({
			dateRange: false,
			reportError: false,
			reportSuccess: false,
			consentError: false,
		});

		// Request
		const { startDate, endDate } = mapDates();
		const request: BiometricsEmailRequest = {
			participantId: participantContext.participant!.id,
			body: {
				startDate,
				endDate,
				email: formData.email!,
			},
		};

		await EndpointsService.dataRetriever
			.createBiometricEmailReport(request)
			.then((response) => {
				if (response) {
					// Show success message
					setShowMessage({
						dateRange: false,
						reportError: false,
						reportSuccess: true,
						consentError: false,
					});
					setTimeout(() => {
						setShowMessage({
							dateRange: false,
							reportError: false,
							reportSuccess: false,
							consentError: false,
						});
					}, 3000);
				} else {
					// There is no data --> show error message
					setShowMessage({
						dateRange: true,
						reportError: false,
						reportSuccess: false,
						consentError: false,
					});
				}
			})
			.catch(() =>
				setShowMessage({
					dateRange: false,
					reportError: true,
					reportSuccess: false,
					consentError: false,
				})
			)
			.finally(() => setSubmittingPreview(false));
	};

	// Submit: Preview
	const handlePreview = async () => {
		// Clean previous error messages
		setShowMessage({
			dateRange: false,
			reportError: false,
			reportSuccess: false,
			consentError: false,
		});

		// Request
		const { startDate, endDate } = mapDates();
		const request: BiometricsPreviewRequest = {
			participantId: participantContext.participant!.id,
			config: {
				responseType: "blob",
			},
			body: {
				startDate,
				endDate,
				generateA1cReport: true,
				generateBpReport: true,
				generateWeightReport: true,
			},
		};

		await EndpointsService.dataRetriever
			.createBiometricPreview(request)
			.then((response) => {
				if (response.size) {
					// There is data --> build PDF
					const blob = new Blob([response], { type: "application/pdf" });
					const url = URL.createObjectURL(blob);
					window.open(url, "_blank"); // Open the file in a new tab
					URL.revokeObjectURL(url); // Clean up the URL after opening
				} else {
					// There is no data --> show error message
					setShowMessage({
						dateRange: true,
						reportError: false,
						reportSuccess: false,
						consentError: false,
					});
				}
			})
			.catch(() =>
				setShowMessage({
					dateRange: false,
					reportError: true,
					reportSuccess: false,
					consentError: false,
				})
			)
			.finally(() => setSubmittingPreview(false));
	};

	return (
		<div className="biometrics-device-summary">
			{/* Information */}
			<div className="mb-2">
				<h4>{t("PARTICIPANT.BIOMETRICS.REPORT.INFO_TITLE")}</h4>
				<p>{t("PARTICIPANT.BIOMETRICS.REPORT.INFO_MSG")}</p>
			</div>

			{/* Form: date range & email */}
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, form, submitting }) => (
					<form
						onSubmit={handleSubmit}
						className="row"
					>
						<FormSection>
							{/* Date range */}
							<div className="col-12">
								<UiInputRadio
									id="date"
									label="PARTICIPANT.BIOMETRICS.REPORT.DATE_TITLE"
									name="date"
									onChange={(e) =>
										setFormData({ ...formData, date: e.value, dateRange: null })
									}
									validations={[Validations.required]}
									disabled={submitting || submittingPreview}
									options={Object.keys(DeviceSummaryRange).map(
										(x) =>
											new ListOption({
												id: x,
												label: `PARTICIPANT.BIOMETRICS.REPORT.${x}`,
											})
									)}
								/>
							</div>

							{/* Date range: custom */}
							{formData.date === DeviceSummaryRange.CUSTOM && (
								<div className="mb-2">
									<div className="col-12 col-sm-6 col-md-3 col-lg-3">
										<UiInputDate
											id="dateRange"
											name="dateRange"
											label="PARTICIPANT.BIOMETRICS.REPORT.DATE_RANGE"
											onChange={(e) =>
												setFormData({
													...formData,
													dateRange: e.target.value as Date[] | null,
												})
											}
											validations={
												formData.date === DeviceSummaryRange.CUSTOM
													? [Validations.required]
													: []
											}
											disabled={
												submitting ||
												submittingPreview ||
												formData.date !== DeviceSummaryRange.CUSTOM
											}
											selectionMode="range"
										/>
									</div>
								</div>
							)}

							{/* Message: no data for the selected date range */}
							{showMessage.dateRange && (
								<div className="col-12 mb-4">
									<UiFieldMessage
										severity={FieldMessageSeverity.DANGER}
										label="PARTICIPANT.BIOMETRICS.REPORT.DATE_MSG_NO_DATA"
									/>
								</div>
							)}

							{/* Email */}
							{participantContext.participant?.emails &&
								participantContext.participant.emails.some(
									(email) => email.emailAddress
								) ? (
								<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5">
									<UiSelect
										id="email"
										label="PARTICIPANT.BIOMETRICS.REPORT.EMAIL_TITLE"
										name="email"
										onChange={(e) =>
											setFormData({ ...formData, email: e.target.value })
										}
										validations={[Validations.required]}
										disabled={submitting || submittingPreview}
										options={
											participantContext.participant?.emails
												?.filter((email) => email.emailAddress)
												?.map(
													(email) =>
														new ListOption({
															id: email.emailAddress!,
															label: email.emailAddress,
														})
												) || []
										}
									/>
								</div>
							) : (
								<div className="">
									<h5>{t("PARTICIPANT.BIOMETRICS.REPORT.EMAIL_TITLE")}</h5>
									<div className="d-flex align-items-center">
										<FaTimesCircle
											className="text-danger"
											fontSize={"1rem"}
										/>
										<span className="mx-1">
											{t("PARTICIPANT.BIOMETRICS.REPORT.EMAIL_MSG_NO_DATA")}
										</span>
										<UiLink
											to={`/participant/${participantContext.participant!.id}/details`}
											label="PARTICIPANT.BIOMETRICS.REPORT.EMAIL_LINK"
										/>
									</div>
								</div>
							)}
						</FormSection>

						<div className="action-buttons">
							<UiButton
								label={
									submittingPreview
										? "PARTICIPANT.BIOMETRICS.REPORT.BUTTON_PREVIEWING"
										: "PARTICIPANT.BIOMETRICS.REPORT.BUTTON_PREVIEW"
								}
								className="p-button-outlined p-button-rounded"
								type="button"
								loading={submittingPreview}
								onClick={(e) => {
									setSubmittingPreview(true);
									handlePreview();
								}}
								disabled={submitting || submittingPreview || !form.getState().valid}
							/>
							<UiButton
								label={
									submitting
										? "PARTICIPANT.BIOMETRICS.REPORT.BUTTON_REPORTING"
										: "PARTICIPANT.BIOMETRICS.REPORT.BUTTON_REPORT"
								}
								className="p-button-rounded"
								type="submit"
								loading={submitting}
								disabled={
									submitting ||
									submittingPreview ||
									!form.getState().valid ||
									!participantContext.participant!.emails?.some(
										(email) => email.emailAddress
									)
								}
							/>

							{/* Message: Error on the backend side */}
							{(showMessage.reportError ||
								showMessage.consentError ||
								showMessage.reportSuccess) && (
									<div className="d-flex justify-content-end mt-2">
										<UiFieldMessage
											severity={
												showMessage.reportSuccess
													? FieldMessageSeverity.SUCCESS
													: FieldMessageSeverity.DANGER
											}
											label={
												showMessage.reportSuccess
													? "PARTICIPANT.BIOMETRICS.REPORT.HTTP_REPORT_SUCCESS"
													: "PARTICIPANT.BIOMETRICS.REPORT.HTTP_REPORT_ERROR"
											}
										/>
									</div>
								)}
						</div>
					</form>
				)}
			/>
		</div>
	);
};
export default BiometricsReport;
