import { useContext, useEffect, useState } from "react";
import { PageProps } from "../../../models/routing.model";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../contexts/Participant.context";
import UiTable from "../../../components/table/Table.component";
import {
	TableColumn,
	TableColumnTemplate,
} from "../../../models/components/table/table-column.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import { TablePagination } from "../../../models/components/table/table-pagination.model";
import { ParticipantSurveyRow } from "../../../models/pages/participant-surveys.model";
import {
	ITableHeaderFilter,
	TableFilterFormData,
	TableFilterType,
} from "../../../models/components/table/table-filter.model";
import { ListOption } from "../../../models/misc.model";
import { ParticipantSurvey } from "../../../models/entities/survey.model";
import { FilterService } from "../../../services/filter/filter.service";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import ModalSurveyResults from "./SurveyResultsModal";
import UiButton from "../../../components/button/Button";
import ModalSurveyLinks from "./SurveyLinksModal";
import { Form } from "react-final-form";
import UiSelect from "../../../components/select/Select.component";
import { ConsentedHealthReport } from "../../../models/entities/participant.model";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FormApi } from "final-form";

const ParticipantSurveys = (props: PageProps) => {
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);

	/* Table */

	// Columns
	const columns: TableColumn[] = [
		{
			field: "surveyName",
			title: "PARTICIPANT.SURVEYS.TABLE.COLUMNS.surveyName",
			sortable: true,
		},
		{
			field: "createdOn",
			title: "PARTICIPANT.SURVEYS.TABLE.COLUMNS.submittedOn",
			template: TableColumnTemplate.DATE,
			templateOptions: {
				dateFormat: {
					day: "2-digit",
					month: "2-digit",
					year: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
				},
			},
			sortable: true,
		},
		{
			field: "actionsTemplate",
			template: TableColumnTemplate.BUTTONS,
			style: {
				textAlign: "center",
			},
		},
	];

	// Rows
	const [originalData, setOriginalData] = useState<ParticipantSurvey[]>([]);
	const [rows, setRows] = useState<ParticipantSurveyRow[]>([]);

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	// Pagination
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: "createdOn",
		sortOrder: -1,
		search: undefined,
		filters: undefined,
	});

	/* Filters  */

	const [filters, setFilters] = useState<ITableHeaderFilter>({
		filters: [
			{
				field: "surveyName",
				type: TableFilterType.SELECT,
				options: {
					columnClass: "col-12 col-md-3 col-lg-3",
					props: {
						label: "PARTICIPANT.SURVEYS.TABLE.FILTER_SURVEY_NAME",
						options: [],
						filter: true,
					},
				},
				value: [],
			},
		],
	});

	const submitFilters = (value: TableFilterFormData) => {
		// Update filters state
		const newFilters = FilterService.updateFiltersValues(
			(filters as ITableHeaderFilter).filters,
			value
		);
		filters.filters = newFilters;

		if (FilterService.hasSelectedFilters(newFilters)) {
			// There are filters selected --> Filter table results
			const filtered: ParticipantSurvey[] = FilterService.filter(
				originalData,
				(filters as ITableHeaderFilter).filters
			);

			// // Update filtered rows
			setRows(
				filtered.map(
					(item) =>
						new ParticipantSurveyRow(item, () => {
							setModalSurveyResults({
								isVisible: true,
								surveyName: item.surveyName,
								surveyResult: item.content,
							});
						})
				)
			);
		} else {
			// No filters selected --> Reset filtered rows
			setRows(
				originalData.map(
					(item) =>
						new ParticipantSurveyRow(item, () => {
							setModalSurveyResults({
								isVisible: true,
								surveyName: item.surveyName,
								surveyResult: item.content,
							});
						})
				)
			);
		}
	};

	/* Data */

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setRows([]);
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.loading });

		await EndpointsService.surveys
			.getSurveysResultsByParticipant({
				config: {
					params: { participantId: participantContext.participant!.id },
				},
			})
			.then((response) => {
				if (response.length > 0) {
					// Map
					setOriginalData(response);
					setRows(
						response.map(
							(item) =>
								new ParticipantSurveyRow(item, () => {
									setModalSurveyResults({
										isVisible: true,
										surveyName: item.surveyName,
										surveyResult: item.content,
									});
								})
						)
					);

					// Map filter options
					const tmpFilters = filters;
					tmpFilters.filters[0].options!.props.options = Array.from(
						new Set(response.map((x) => x.surveyName))
					).map((x) => new ListOption({ id: x, label: x }));
					setFilters({ ...tmpFilters });
				} else {
					setMessage({ severity: FieldMessageSeverity.INFO, label: messages.empty });
				}
			})
			.catch((error) =>
				setMessage({ severity: FieldMessageSeverity.DANGER, label: messages.error })
			);
	};

	/* Modal: survey results */

	const [modalSurveyResults, setModalSurveyResults] = useState<{
		isVisible: boolean;
		surveyName: string | null;
		surveyResult: any | null;
	}>({
		isVisible: false,
		surveyName: null,
		surveyResult: null,
	});

	/* Modal: survey links */

	const [modalSurveyLinks, setModalSurveyLinks] = useState<{ isVisible: boolean }>({
		isVisible: false,
	});

	/* HIPAA consent */

	const [formData, setFormData] = useState<{
		consentedHealthReport: ConsentedHealthReport | null;
		submitting: boolean;
		submitError: boolean;
	}>({
		consentedHealthReport: participantContext.participant?.consentedHealthReport ?? null,
		submitting: false,
		submitError: false,
	});

	const handleSubmit = async () => { };

	const update = async (consentedHealthReport: ConsentedHealthReport | null) => {
		setFormData({
			consentedHealthReport,
			submitting: true,
			submitError: false,
		});

		await EndpointsService.dataRetriever
			.updateProfileSubscriptions({
				body: {
					participantId: participantContext.participant!.id,
					consentedHealthReport,
				},
			})
			.then((response) => {
				setFormData({
					submitting: false,
					submitError: false,
					consentedHealthReport: response?.consentedHealthReport,
				});
			})
			.catch(() =>
				setFormData({
					consentedHealthReport:
						participantContext.participant?.consentedHealthReport ?? null,
					submitError: true,
					submitting: false,
				})
			);
	};

	return (
		<>
			<UiTable
				useAsCard={true}
				title="PARTICIPANT.SURVEYS.TITLE"
				dataKey="surveyResultId"
				customHeader={
					<div className="d-inline-flex align-items-center">
						<UiButton
							label="PARTICIPANT.SURVEYS.MODAL_LINKS.TITLE"
							type="button"
							className="p-button-rounded p-button-sm"
							onClick={() => setModalSurveyLinks({ isVisible: true })}
						/>
						<Form
							onSubmit={handleSubmit}
							initialValues={formData}
							render={({ handleSubmit, submitting }) => (
								<form
									onSubmit={handleSubmit}
									className="ms-3"
									style={{ minWidth: "250px" }}
								>
									<UiSelect
										id="consentedHealthReport"
										label="PARTICIPANT.SURVEYS.HIPAA_CONSENTED"
										name="consentedHealthReport"
										removeBottomSpacer={true}
										onChange={(e) => update(e.target.value ?? null)}
										disabled={formData.submitting}
										options={Object.keys(ConsentedHealthReport).map(
											(x) =>
												new ListOption({
													id: x,
													label: `ENUMS.HIPAA_CONSENT_STATUS.${x}`,
												})
										)}
										showClear={true}
									/>
									{formData.submitError && (
										<div>
											<UiFieldMessage
												severity={FieldMessageSeverity.DANGER}
												label={"UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR"}
											/>
										</div>
									)}
								</form>
							)}
						/>
					</div>
				}
				columns={columns}
				value={rows}
				message={message}
				pagination={pagination}
				paginationFn={(event: TablePagination) => {
					const newPagination: TablePagination = {
						...pagination,
						page: event.page,
						rows: event.rows,
						sortField: event.sortField,
						sortOrder: event.sortOrder,
						first: event.first,
					};
					setPagination(newPagination);
				}}
				filter={filters}
				filterFn={submitFilters}
			/>

			{/* Modal: Survey Results */}
			{modalSurveyResults.isVisible && (
				<ModalSurveyResults
					isVisible={modalSurveyResults.isVisible}
					surveyName={modalSurveyResults.surveyName!}
					surveyResult={modalSurveyResults.surveyResult!}
					closeModal={() => {
						setModalSurveyResults({
							isVisible: false,
							surveyName: null,
							surveyResult: null,
						});
					}}
				/>
			)}

			{/* Modal: Survey Results */}
			{modalSurveyLinks.isVisible && (
				<ModalSurveyLinks
					isVisible={modalSurveyLinks.isVisible}
					participant={participantContext.participant!}
					closeModal={() => setModalSurveyLinks({ isVisible: false })}
				/>
			)}
		</>
	);
};

export default ParticipantSurveys;
